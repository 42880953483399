@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] {
  display: none !important;
}

@font-face {
  font-family: 'NunitoSans';
  src:
    url('./assets/fonts/NunitoSans-Light.woff2') format('woff2'),
    url('./assets/fonts/NunitoSans-Light.woff') format('woff');
  font-weight: 300; /* Light */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans';
  src:
    url('./assets/fonts/NunitoSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/NunitoSans-Regular.woff') format('woff');
  font-weight: 400; /* Regular */
  font-style: normal;
  font-display: swap;
}

:root {
  --swiper-theme-color: #0891b2 !important;

}

@media (min-width: 1280px) {
  :root {
    --swiper-theme-color: #fff !important;
  }
}